<template>
  <div class="bg-blue4 d-flex fs14 p-5">
    <img src="/assets/img/icons/info.SVG" alt="" class="info-icon">
    <div>
      <p class="mb-5"><b>COVID-19 {{ $t('Information') }}</b></p>
      <p v-html="$t('Please check your itinerary, including layovers, for <b>travel restrictions</b> prior to booking. A displayed itinerary is not confirmation of your eligibility to travel.')">
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CovideNotify"
}
</script>

<style lang="scss" scoped>
.info-icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  margin-top: 3px;
}

.rtl-type {
  .info-icon {
    margin-right: unset;
    margin-left: 10px;
  }
}
</style>