<template>
  <section>
    <div class="mobile-filter-buttons my-5">
      <button 
        class="primary-btn d-flex align-items-center mx-2"
        @click="openFilterModal"
      >
        {{ $t('Search') }}
        <img src="/assets/img/icons/search-light-white.svg" alt="" class="seach-btn-img">
      </button>
    </div>
    <section class="filter desktop-filter" :class="hasTypeDeal ? '' : 'no-type-deal'">
      <div class="filter-item deal-input">
        <label for="" class="d-flex align-items-center mb-2">
          <img src="/assets/img/icons/umbrella-beach-light.svg" alt="">
          <p class="mx-2">{{ $t("Type of Deal") }}</p>
        </label>
        <vue-select
          :options="deal.options"
          class="input cus-select border-option-dropdown bd-radius"
          v-model="deal.selected"
          :placeholder="$t('Select')"
        ></vue-select>  
      </div>

      <div class="filter-item from-input">
        <label for="" class="d-flex align-items-center mb-2">
          <img src="/assets/img/icons/plane.svg" alt="">
          <p class="mx-2">{{ $t("From") }}</p>
        </label>
        <input class="input bd-radius" type="text" v-model="from" disabled />
      </div>
      <div class="filter-item to-input">
        <label for="" class="d-flex align-items-center mb-2">
          <img src="/assets/img/icons/plane.svg" alt="">
          <p class="mx-2">{{ $t("To") }}</p>
        </label>
        <vue-select
          :options="arrival.options"
          class="input bd-radius cus-select border-option-dropdown"
          v-model="arrival.selected"
          @input="handleChangeInput"
        ></vue-select>
      </div>
      <div class="filter-item range-input">
        <label for="" class="d-flex align-items-center mb-2">
          <img src="/assets/img/icons/calendar-alt-light-white.svg" alt="">
          <p class="mx-2">{{ $t("Dates") }}</p>
        </label>
        <date-range-picker ref="picker" :locale-data="dateRangeLocal" :timePicker="false" :timePicker24Hour="true"
          :showWeekNumbers="false" :showDropdowns="true" :autoApply="false" v-model="dateRange"
          :linkedCalendars="true" @update="handleChangeInput" class="cus-daterange-picker range-datepicker">
          <template v-slot:input="picker" style="min-width: 350px;">
            {{ picker.startDate | formatDate }} - {{ picker.endDate | formatDate }}
          </template>
          <template #ranges="ranges">
            <div class="ranges">
              <ul>
                <li v-for="(range, name) in ranges.ranges" :key="name" @click="ranges.clickRange(range)"
                  v-if="name != 'This year'">
                  <b>{{ $t(name) }}</b>
                </li>
              </ul>
            </div>
          </template>
        </date-range-picker>


        <!-- <date-range-picker v-model="dateRange" class="range-datepicker">
        </date-range-picker> -->
      </div>
      <div class="filter-item travelers-input p-relative">
        <label for="" class="d-flex align-items-center mb-2">
          <img src="/assets/img/icons/people.svg" alt="">
          <p class="mx-2">{{ $t("Travelers") }}</p>
        </label>
        <input 
          class="input bd-radius text-center" 
          type="text" 
          v-model="totalPassenger" 
          @click="togglePassenger" 
          readonly
        />
        <div class="passengers-list bg-white p-5 bd-radius" v-show="isShowPassenger">
          <p>
            <b>Passengers</b>
          </p>
          <div class="d-flex align-items-center justify-content-between mt-7">
            <div class="d-flex align-items-center">
              <img src="/assets/img/icons/account.svg" alt="" class="user-icon">
              <div>
                <p><b>Adults</b></p>
                <p class="fs12">Obber 11</p>
              </div>
            </div>
            <div class="d-flex">
              <div class="minus-btn df-c" @click="changePassangers('adults', '-')">
                -
              </div>
              <p class="df-c number-block">
                {{adults}}
              </p>
              <div class="plus-btn df-c" @click="changePassangers('adults', '+')">
                +
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between mt-7">
            <div class="d-flex align-items-center">
              <img src="/assets/img/icons/face-man-outline.svg" alt="" class="user-icon">
              <div>
                <p><b>Children</b></p>
                <p class="fs12">age 2-11</p>
              </div>
            </div>
            <div class="d-flex">
              <div class="minus-btn df-c" @click="changePassangers('children', '-')">
                -
              </div>
              <p class="df-c number-block">
                {{children}}
              </p>
              <div class="plus-btn df-c" @click="changePassangers('children', '+')">
                +
              </div>
            </div>
          </div>
          <!-- <div class="d-flex align-items-center justify-content-between mt-7">
            <div class="d-flex align-items-center">
              <img src="/assets/img/icons/baby-face-outline.svg" alt="" class="user-icon">
              <div>
                <p><b>Infants</b></p>
                <p class="fs12">Under 2</p>
              </div>
            </div>
            <div class="d-flex">
              <div class="minus-btn df-c" @click="changePassangers('infants', '-')">
                -
              </div>
              <p class="df-c number-block">
                {{infants}}
              </p>
              <div class="plus-btn df-c" @click="changePassangers('infants', '+')">
                +
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <button class="button filter__button bd-radius" @click="clickFilterBtn()"></button>
    </section>
    <section class="mobile-filter-modal" v-show="isFilterModalVisible">
      <p class="fs20 color-white text-center mb-5">{{ $t("Search your desire") }}</p>
      <div class="filter-item mt-7 deal-input">
        <label for="" class="d-flex align-items-center mb-2">
          <img src="/assets/img/icons/umbrella-beach-light.svg" alt="">
          <p class="mx-2 color-white">{{ $t("Type of Deal") }}</p>
        </label>
        <vue-select
          :options="deal.options"
          class="input cus-select border-option-dropdown bd-radius"
          v-model="deal.selected"
          :placeholder="$t('Select')"
        ></vue-select>  
      </div>
      <div class="filter-item mt-7 from-input">
        <label for="" class="d-flex align-items-center mb-2">
          <img src="/assets/img/icons/plane.svg" alt="">
          <p class="mx-2 color-white">{{ $t("From") }}</p>
        </label>
        <input class="input bd-radius" type="text" v-model="from" />
      </div>
      <div class="filter-item mt-7 to-input">
        <label for="" class="d-flex align-items-center mb-2">
          <img src="/assets/img/icons/plane.svg" alt="">
          <p class="mx-2 color-white">{{ $t("To") }}</p>
        </label>
        <vue-select
          :options="arrival.options"
          class="input bd-radius cus-select border-option-dropdown"
          v-model="arrival.selected"
        ></vue-select>
      </div>
      <div class="filter-item mt-7 range-input">
        <label for="" class="d-flex align-items-center mb-2">
          <img src="/assets/img/icons/calendar-alt-light-white.svg" alt="">
          <p class="mx-2 color-white">{{ $t("Dates") }}</p>
        </label>
        <date-range-picker v-model="dateRange" class="range-datepicker">
        </date-range-picker>
      </div>
      <div class="filter-item mt-7 travelers-input p-relative">
        <label for="" class="d-flex align-items-center mb-2">
          <img src="/assets/img/icons/people.svg" alt="">
          <p class="mx-2 color-white">{{ $t("Travelers") }}</p>
        </label>
        <input 
          class="input bd-radius text-center" 
          type="text" 
          v-model="totalPassenger" 
          @click="togglePassenger" 
          readonly
        />
        <div class="passengers-list bg-white p-5 bd-radius" v-show="isShowPassenger">
          <p>
            <b>Passengers</b>
          </p>
          <div class="d-flex align-items-center justify-content-between mt-7">
            <div class="d-flex align-items-center">
              <img src="/assets/img/icons/account.svg" alt="" class="user-icon">
              <div>
                <p><b>Adults</b></p>
                <p class="fs12">Obber 11</p>
              </div>
            </div>
            <div class="d-flex">
              <div class="minus-btn df-c" @click="changePassangers('adults', '-')">
                -
              </div>
              <p class="df-c number-block">
                {{adults}}
              </p>
              <div class="plus-btn df-c" @click="changePassangers('adults', '+')">
                +
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between mt-7">
            <div class="d-flex align-items-center">
              <img src="/assets/img/icons/face-man-outline.svg" alt="" class="user-icon">
              <div>
                <p><b>Children</b></p>
                <p class="fs12">age 2-11</p>
              </div>
            </div>
            <div class="d-flex">
              <div class="minus-btn df-c" @click="changePassangers('children', '-')">
                -
              </div>
              <p class="df-c number-block">
                {{children}}
              </p>
              <div class="plus-btn df-c" @click="changePassangers('children', '+')">
                +
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between mt-7">
            <div class="d-flex align-items-center">
              <img src="/assets/img/icons/baby-face-outline.svg" alt="" class="user-icon">
              <div>
                <p><b>Infants</b></p>
                <p class="fs12">Under 2</p>
              </div>
            </div>
            <div class="d-flex">
              <div class="minus-btn df-c" @click="changePassangers('infants', '-')">
                -
              </div>
              <p class="df-c number-block">
                {{infants}}
              </p>
              <div class="plus-btn df-c" @click="changePassangers('infants', '+')">
                +
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center filter-action-block mt-5">
        <button class="primary-btn" @click="clickFilterBtn()">
          {{ $t('Search') }}
          <img 
            src="/assets/img/icons/search-light-white.svg" 
            alt="" 
            class="seach-btn-img"
          >
        </button>
        <button 
          class="primary-btn bg-red6"  
          @click="closeFilterModal()"
        >
          {{ $t("Cancel") }}
        </button>
      </div>
    </section>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: 'TourismFilter',
  props: {
    hasTypeDeal: {
      default: true,
      type: Boolean
    },
    pToId: ''
  },
  watch: {
    pToId: {
      deep: true,
      handler(newValue) {
        if (this.arrival.selected.code != newValue) {
          this.arrival.selected = this.arrival.options.find(el=>{
            return el.code == newValue;
          })
        }
      }
    },
  },
  data() {
    let startDate = new Date();
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 90);
    let night = ['1', '89'];

    return {
      deal: {
        options: [
          {
            code: '',
            label: this.$t("All")
          },
          {
            code: 'NOFSHON',
            label: this.$t("NOFSHON")
          },
          {
            code: 'ORGANIZED_TOUR',
            label: this.$t("Organized Tour")
          },
        ],
        selected: {
          code: '',
          label: this.$t("All")
        }
      },
      from: "TLV",
      arrival: {
        options: [],
        selected: '',
      },
      dateRange: { 
        startDate: startDate, 
        endDate: endDate, 
        night: night 
      },
      isShowPassenger: false,
      travelers: 0,
      adults: 0,
      children: 0,
      infants: 0,
      isFilterModalVisible: false
    }
  },
  computed: {
    ...mapState({
      destinations: (state) => state.destination.destinationData_receipts,
    }),
    totalPassenger() {
      return this.adults + this.children + this.infants;
    },

    query_to() {
      return this.$route.query.to || null
    },
    query_start_date() {
      return this.$route.query.start_date || null
    },
    query_end_date() {
      return this.$route.query.end_date || null
    },
    query_passengers() {
      return this.$route.query.passengers || null
    },

  },
  async created() {
    // create To dropdown list
    if (this.destinations.length == 0) {
      await this.getDestination();
    }
    if (this.destinations.length != 0) {
      for (const key in this.destinations) {
        if (this.destinations[key].active) {
          this.arrival.options.push({
            label: this.destinations[key].name,
            code: this.destinations[key].id,
          });
        }
      }
      this.arrival.options.unshift({
        label: this.$t("All destinations"),
        code: null,
      });
      this.arrival.selected = this.arrival.options[0];
    }

    this.initFilter();
  },
  methods: {
    ...mapActions("destination", {
      getDestination: "getDestination",
    }),
    initFilter() {
      if (this.query_to) {
        this.arrival.selected = this.arrival.options.find(el=>{
          return el.code == this.query_to;
        })
      }

      if (this.query_start_date && this.query_end_date) {
        this.dateRange.startDate = moment(this.query_start_date);
        this.dateRange.endDate = moment(this.query_end_date);
      }

      if (this.query_passengers) {
        this.adults = (this.query_passengers.match(new RegExp("A", "g")) || []).length;
        this.children = (this.query_passengers.match(new RegExp("C", "g")) || []).length;
      }

      this.handleChangeInput();
    },
    clickFilterBtn() {
      this.$emit('handleFilter');
    },
    togglePassenger() {
      this.isShowPassenger = !this.isShowPassenger;
    },
    changePassangers(pType, changeType) {
      if (changeType == "-" && this[pType] != 0) {
        this[pType] = this[pType] - 1;
      }
      if (changeType == "+") {
        this[pType] = this[pType] + 1;
      }
      this.handleChangeInput();
    },
    openFilterModal() {
      $("html").css("overflow", "hidden");
      this.isFilterModalVisible = true;
    },
    closeFilterModal() {
      $("html").css("overflow", "auto");
      this.isFilterModalVisible = false;
    },
    handleChangeInput() {
      let passenger = '';
      for (let i = 0; i < this.adults; i++) {
        passenger += 'A';
      }

      for (let i = 0; i < this.children; i++) {
        passenger += 'C';
      }

      const data = {
        to: this.arrival.selected.code,
        to_name: this.arrival.selected.label,
        start_date: this.dateRange.startDate,
        end_date: this.dateRange.endDate,
        passenger: passenger
      }
      this.$emit('on-change', data);
    }
  }
}
</script>

<style lang="scss">
.filter {
  .range-datepicker .reportrange-text {
    height: 38px;
    border-radius: 5px;
  }
}

.reportrange-text {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
</style>

<style lang="scss" scoped>
.filter {
  label {
    color: white;
  }
  .deal-input,
  .from-input,
  .to-input {
    width: 18%;
  }
  .travelers-input {
    width: 10%;
    .passengers-list {
      position: absolute;
      top: 80px;
      right: 0;
      font-size: 16px;
      width: 300px;
      z-index: 22;
      &:after {
        content: '';
        display: block;  
        position: absolute;
        right: 40px;
        bottom: 100%;
        width: 0;
        height: 0;
        border-bottom: 15px solid #fff;
        border-top: 15px solid transparent;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
      }
      .minus-btn {
        width: 30px;
        height: 30px;
        background-color: #F2F2F2;
      }
      .plus-btn {
        width: 30px;
        height: 30px;
        background-color: #D9D9D9;
      }
      .user-icon {
        width: 35px;
      }
      .number-block {
        width: 35px;
      }
    }
  }
  .range-input {
    width: 25%;
  }
  &.no-type-deal {
    .deal-input {
      display: none;
      image {
        height: 16px;
      }
    }
    .from-input,
    .to-input {
      width: 22%;
    }
    .range-input {
      width: 32%;
    }
    .travelers-input {
      width: 15%;
    }
  }
}

.mobile-filter-buttons {
  display: none;
}

@media screen and (max-width: 800px) {
  .desktop-filter {
    display: none;
  }
  .mobile-filter-buttons {
    display: block;
    .primary-btn {
      background-color: #002036;
      width: auto;
      font-size: 12px;
      font-weight: initial;
      height: auto;
      padding: 5px;
      border-radius: 8px;
      .seach-btn-img {
        height: 15px;
        margin-right: 10px;
      }
    }
  }
  .mobile-filter-modal {
    position: fixed;
    top: 60px;
    width: 100vw;
    left: 0;
    right: 0;
    background-color: #002036;
    height: calc(100vh - 60px);
    padding: 15px;
    z-index: 22;
    overflow: auto;
    .filter__item {
      padding: 0;
      height: unset;
      margin-top: 15px;
      &::after {
        display: none;
      }
      .cus-select {
        height: 40px;
        padding-top: 0;
      }
    }
    .filter-action-block {
      .primary-btn {
        padding: 0 20px;
        margin: 0 10px;
        border-radius: 5px;
        .seach-btn-img {
          margin-right: 15px;
        }
      }
    }
  }
  .ltr-type {
     .mobile-filter-buttons {
      .primary-btn {
        .seach-btn-img {
          margin-left: 10px;
          margin-right: unset;
        }
      }
    }
    .mobile-filter-modal {
      .filter-action-block {
        .primary-btn {
          .seach-btn-img {
            margin-right: unset;
            margin-left: 15px;
          }
        }
      }
    }
  }
}
</style>