<template>
  <div class="bdbr-3 bd-radius fs12 weight-700 p-5">
    <p class="fs15 mb-10">
      <b>{{ $t('Baggage info') }}</b>
    </p>
    <div class="d-flex align-items-center baggage-item">
      <img src="/assets/img/icons/V.svg" alt="" class="baggage-option mb-2">
      <div class="d-flex align-items-center justify-content-between baggage-data pb-2 w-100">
        <div class="d-flex align-items-center">
          <img src="/assets/img/icons/side bag.svg" alt="">
          <p class="mx-5 mb-0">{{ $t('Personal Item') }}</p>
        </div>
        <p class="baggage-state mb-0">{{ flight.MaxWeightHandbag }} Kg</p>
      </div>
    </div>
    <div class="d-flex align-items-center baggage-item mt-2">
      <img src="/assets/img/icons/$.svg" alt="" class="baggage-option mb-2">
      <div class="d-flex align-items-center justify-content-between baggage-data pb-2 w-100">
        <div class="d-flex align-items-center">
          <img src="/assets/img/icons/caryonbag.svg" alt="">
          <p class="mx-5 mb-0">{{ $t('Cabin Baggage') }}</p>
        </div>
        <p class="baggage-state mb-0">{{ flight.MaxWeight }}</p>
      </div>
    </div>
    <div class="d-flex align-items-center baggage-item mt-2">
      <img src="/assets/img/icons/X.svg" alt="" class="baggage-option mb-2">
      <div class="d-flex align-items-center justify-content-between baggage-data pb-2 w-100">
        <div class="d-flex align-items-center">
          <img src="/assets/img/icons/checkedbag.svg" alt="">
          <p class="mx-5 mb-0">{{ $t('Checked Baggage') }}</p>
        </div>
        <p class="baggage-state mb-0">Not Available</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaggageInfo",
  props: {
    flight: {
      type: Object,
      required: true,
      default: () => {}
    },
  },
}
</script>

<style lang="scss" scoped>
.baggage-item {
  .baggage-option {
    margin-right: 10px;
    width: 12px;
  }
  .baggage-data {
    border-bottom: 1px solid #c5c5c5;
    .baggage-state {
      width: 100px;
    }
  }
}

.rtl-type {
  .baggage-item {
    .baggage-option {
      margin-right: unset;
      margin-left: 10px;
    }
  }
}
</style>