<template>
  <div class="d-flex justify-content-between w-100 fs14" v-if=Object.keys(deal).length>
    <div class="text-center">
      <p class="mb-1">
        <b>Tel-Aviv</b> <br>
        (TLV)
      </p>
      <p>
        <b>{{ deal.departureDate | formatDate }}</b>
      </p>
      <div class="d-flex justify-content-center fs12">
        <img src="/assets/img/icons/plane-departure-light-black.svg" alt="" class="mx-2">
        {{ deal.flightsPairs[0].to.depTime }}
      </div>
      <div class="df-c">
        <img src="/assets/img/icons/flight connection.svg" alt="">
      </div>
    </div>

    <div class="df-c flex-column pt-7">
      <img v-if="deal.dealLength=='O/W'" src="/assets/img/icons/arrows-h-alt-red.svg" alt="">
      <img v-else src="/assets/img/icons/flight.svg" alt="">

      <p v-if="deal.dealLength=='O/W'" class="red--text fs10">{{ $t( "One Way" ) }}</p>
      <p v-else>{{ deal.dealLength }} Days</p>
      <slot name="additionalData">
      </slot>
    </div>
    
    <div class="text-center">
      <p class="mb-1">
        <b>{{ deal.destinationName || '---' }}</b> <br> ({{ deal.dealDestination }})
      </p>
      <p>
        <b>{{ calcEndDate(deal.departureDate, deal.dealLength) }}</b>
      </p>
      <div class="d-flex justify-content-center fs12">
        <img src="/assets/img/icons/plane-departure-light-black.svg" alt="" class="mx-2">
        {{ calcEndTime(deal.flightsPairs[0].to.depTime, deal.flightsPairs[0].to.flightDuration) }}
      </div>
      <div class="df-c">
        <img src="/assets/img/icons/flightDirect.svg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import RateStar from '../RateStar.vue';
export default {
  name: "FlightDesData",
  props: {
    deal: {
      type: Object
    }
  },
  components: {
    RateStar
  }

}
</script>

<style lang="scss" scoped>
  p {
    line-height: 1.1em;
  }
</style>