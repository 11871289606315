<template>
  <div class="bdbr-3 bd-radius p-2 fs14 bg-grey6">
    <p class="mb-7">
      <b>To Berlin</b>  
    </p>
    <div class="d-flex align-items-center calendar-block">
      <img src="/assets/img/icons/calendar-day-light.svg" alt="" class="mx-5">
      Friday 14/04/21
    </div>
    <div class="d-flex ticket-table mt-2">
      <div class="title-column">
        <div class="df-c bg-white left-white-column mt-5">
          <b>12:45</b>
        </div>
        <div class="df-c">
          <img src="/assets/img/icons/luftansa.svg" alt="" class="company-icon">
        </div>
        <div class="df-c bg-white left-white-column">
          <b>14:30</b>
        </div>
        <div class="double-column">
        </div>
        <div class="df-c bg-white left-white-column">
          <b>16:40</b>
        </div>
        <div class="df-c">
          <img src="/assets/img/icons/Austrian_Airlines.svg" alt="" class="company-icon">
        </div>
        <div class="df-c bg-white left-white-column">
          <b>18:30</b>
        </div>
        <div></div>
      </div>
      <div class="data-column">
        <div class="d-flex justify-content-between align-items-center bg-white right-white-column mt-5 px-2">
          <div class="mx-7">
            <p><b>Tel-Aviv</b></p>
            <p class="fs12">Ben-Gurion (TLV)</p>
          </div>
          <div class="blue-badge">
            Economy
          </div>
        </div>
        <div class="d-flex align-items-center px-5">
          <img src="/assets/img/icons/plane-light.svg" alt="">
          <div class="white-badge mx-5">
            Lufthansa
          </div>
          <div class="blue-badge">
            1h 45m
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center bg-white right-white-column px-2">
          <div class="mx-7">
            <p><b>Prague</b></p>
            <p class="fs12">Vaclav Havel (PRG)</p>
          </div>
        </div>
        <div class="double-column pt-5">
          <div class="d-flex align-items-center">
            <img src="/assets/img/icons/stopwatch-light.svg" alt=""  class="mx-5">
            <p>2h 10m layover</p>
          </div>
          <div class="d-flex align-items-center mt-5">
            <img src="/assets/img/icons/check.svg" alt="" class="mx-5">
            <p class="fs11">Connection protected by the carrier</p>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center bg-white right-white-column px-2">
          <div class="mx-7">
            <p><b>Prague</b></p>
            <p class="fs12">Vaclav Havel (PRG)</p>
          </div>
          <div class="blue-badge">
            Economy
          </div>
        </div>
        <div class="d-flex align-items-center px-5">
          <img src="/assets/img/icons/plane-light.svg" alt="">
          <div class="white-badge mx-5">
            Austrian airlines
          </div>
          <div class="blue-badge">
            1h 45m
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center bg-white right-white-column px-2">
          <div class="mx-7">
            <p><b>Berlin</b></p>
            <p class="fs12">Willy Brandt (BER)</p>
          </div>
        </div>
        <div class="d-flex align-items-center px-5">
          <img src="/assets/img/icons/map-marker-alt-light-black.svg" alt="">
          <div class="mx-7">
            <p class="fs12">Arrived at destination</p>
            <p><b>Berlin</b></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FligntTicket',
}
</script>

<style lang="scss" scoped>
  .ticket-table {
    .title-column {
      border-right: 1px solid #d6d6d6;
      width: 70px;
      &>div {
        height: 40px;
      }
    }
    .data-column {
      width: calc(100% - 70px);
      &>div {
        height: 40px;
      }
    }
    .left-white-column {
      border: 1px solid #c5c5c5;
      border-right: none;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    .right-white-column {
      border: 1px solid #c5c5c5;
      border-left: none;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .company-icon {
      // height: 10px;
      width: 56px;
    }
    .double-column {
      height: 70px !important;
    }
  }
  .calendar-block {
    padding-left: 48px;
  }
  .rtl-type {
    .calendar-block {
      padding-left: unset;
      padding-right: 48px;
    }
    .ticket-table {
      .title-column {
        border-right: unset;
        border-left: 1px solid #d6d6d6;
      }
      .left-white-column {
        border-right: 1px solid #d6d6d6;
        border-left: none;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      .right-white-column {
        border-left: 1px solid #d6d6d6;
        border-right: none;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
</style>